import { Vue, Component } from "vue-property-decorator";
import { isEmpty } from "@helpers";
import API from "@api";

@Component
export default class ProfilePromocode extends Vue {
  private promocode = "";
  private loading = false;

  private get disabledSubmitButton() {
    return isEmpty(this.promocode);
  }

  private async onSubmitForm() {
    if (this.loading) return;

    this.loading = true;

    try {
      await API.promocode.applyPromocode({
        promocode: this.promocode.trim(),
      });

      this.loading = false;

      this.$notify({
        type: "success",
        title: this.$vuetify.lang.t(
          "$vuetify.info.promocode_successfully_applied"
        ),
      });
    } catch {
      this.loading = false;
    }
  }

  private onClickHistoryPromocodes() {
    this.$emit("click:history-promocodes");
  }
}
